import React from "react"
import { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Block } from "baseui/block"
import { JobList } from "../components/atoms"
import { JobDetail } from "../components/molecules"
import CloudApi from "cloudApi/api"
import withJob from "hoc/withJob"
import Header from "../components/molecules/Header"
import { JobFilter } from "../components/organisms"
import InfiniteScroll from "react-infinite-scroller"

const Index = ({ jobState, jobDispatch }) => {
  const [debounce, setDebounce] = useState(true)
  const [params, setParams] = useState(null)

  useEffect(() => {
    const windowGlobal = typeof window !== "undefined" && window
    const { localStorage } = windowGlobal
    const { jobs, appliedJobs } = jobState

    jobs.length === 0 &&
      CloudApi.getJobLists({ offset: 0 }).then(jobs =>
        jobDispatch({ type: "FETCH_JOBS_DATA", payload: jobs })
      )
    CloudApi.getTags().then(tags =>
      jobDispatch({ type: "FETCH_TAGS_DATA", payload: tags })
    )
    CloudApi.getPrices().then(prices =>
      jobDispatch({ type: "FETCH_JOBS_PRICES", payload: prices })
    )
    localStorage.getItem("tokenId") &&
      localStorage.getItem("role") === "student" &&
      appliedJobs.length === 0 &&
      CloudApi.getJobsApplied().then(appliedJob =>
        jobDispatch({ type: "FETCH_APPLIED_JOBS_DATA", payload: appliedJob })
      )
  }, [])

  const [activeJobIndex, setActiveJobIndex] = useState(0)

  const getWindowDimensions = () => {
    const windowGlobal = typeof window !== "undefined" && window
    const { innerWidth: width, innerHeight: height } = windowGlobal
    return {
      width,
      height,
    }
  }

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    )

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
  }

  const getJobsApplied = () =>
    CloudApi.getJobsApplied().then(appliedJob =>
      jobDispatch({ type: "FETCH_APPLIED_JOBS_DATA", payload: appliedJob })
    )

  const getJobDetail = id =>
    CloudApi.getJobDetail(id).then(job =>
      jobDispatch({
        type: "FETCH_JOBS_DETAIL",
        payload: job,
      })
    )

  const getMoreJobs = () => {
    const { jobs } = jobState
    setDebounce(false)
    if (params) {
      CloudApi.getJobListsByFilter({
        filterData: params,
        offset: jobs.length,
      })
        .then(jobs => {
          jobDispatch({ type: "FETCH_JOBS_DATA", payload: jobs })
          setDebounce(true)
        })
        .catch(() => {
          jobDispatch({ type: "STOP_FETCH_JOBS" })
        })
    } else {
      CloudApi.getJobLists({
        offset: jobs.length,
      })
        .then(jobs => {
          jobDispatch({ type: "FETCH_JOBS_DATA", payload: jobs })
          setDebounce(true)
        })
        .catch(() => {
          jobDispatch({ type: "STOP_FETCH_JOBS" })
        })
    }
  }

  const { width } = useWindowDimensions()
  const { jobs, appliedJobs, shouldGetMoreJobs } = jobState

  return (
    <Block>
      <Header />
      <JobFilter setParams={setParams} setActiveJobIndex={setActiveJobIndex} />
      <Block display={"flex"} width={"100%"} overflow={"hidden"}>
        <Block
          maxHeight={["100vh", "100vh", "100vh", "100vh"]}
          overflow={"auto"}
          display={"flex"}
          width={["100%", "100%", "35%", "35%"]}
        >
          {jobs.length > 0 && (
            <InfiniteScroll
              style={{ width: "100%" }}
              pageStart={0}
              useWindow={false}
              loadMore={() => getMoreJobs()}
              hasMore={debounce && shouldGetMoreJobs}
            >
              {jobs.map((job, index) => (
                <JobList
                  key={job.id}
                  title={job.title}
                  department={job.department}
                  jobTags={job.tags}
                  price={job.rate}
                  active={activeJobIndex === index}
                  dates={job.dates}
                  onClick={() => {
                    if (width > 640) {
                      return setActiveJobIndex(index)
                    } else return navigate(`/job-detail?${jobs[index].id}`)
                  }}
                  start_time={job.start_time}
                  end_time={job.end_time}
                  rateUnit={job.rate_unit}
                />
              ))}
            </InfiniteScroll>
          )}
        </Block>
        <Block
          width={["0px", "0px", "65%", "65%"]}
          display={["none", "none", "block", "block"]}
          maxHeight={["none", "none", "100vh", "100vh"]}
          overflow={"auto"}
        >
          {jobs.length > 0 && (
            <JobDetail
              job={jobs[activeJobIndex]}
              jobTags={jobs[activeJobIndex].tags}
              appliedJobs={appliedJobs}
              getJobsApplied={getJobsApplied}
              getJobDetail={getJobDetail}
              jobDispatch={jobDispatch}
            />
          )}
        </Block>
      </Block>
    </Block>
  )
}

export default withJob(Index)
