import React from "react"
import moment from "moment"
import { Block } from "baseui/block"
import { Label2, Paragraph2, Paragraph3 } from "baseui/typography"
import { Tag } from "baseui/tag"
import { Time16 } from "@carbon/icons-react"

const JobList = ({
  id,
  title,
  department,
  jobTags,
  price,
  active,
  onClick,
  rateUnit,
  start_time,
  end_time,
}) => {
  const renderRateUnit = rateUnit => {
    if (rateUnit === "baht/hour") return "บาท/ชั่วโมง"
    return "บาท"
  }
  return (
    <Block
      onClick={() => onClick()}
      padding={"15px"}
      overrides={{
        Block: {
          style: {
            borderRight: active ? "2px solid #fd7c2e" : "1px solid #ededed",
            cursor: "pointer",
            borderBottom: "none",
            backgroundColor: active ? "#ffecde" : "white",
            "@media (max-width: 640px)": {
              borderBottom: "1px solid #ededed",
              backgroundColor: "white",
              borderRight: "1px solid #ededed",
            },
          },
        },
      }}
    >
      <Label2 style={{fontWeight:'bold'}}>{title}</Label2>
      <Paragraph3 style={{fontWeight:300}}>{department}</Paragraph3>
      {jobTags &&
        jobTags.length > 0 &&
        jobTags.map((tag, index) => (
          <Tag
            key={index}
            closeable={false}
            variant={"outlined"}
            kind="primary"
            overrides={{
              Root: {
                style: {
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                },
              },
            }}
          >
            {tag}
          </Tag>
        ))}

      <Block display={"flex"} justifyContent={"space-between"}>
        <Block display={"flex"} alignItems={"center"}>
          <Time16 style={{ paddingRight: 6 }} />
          <Paragraph2>
            {start_time && moment(start_time).format("HH:mm")} -{" "}
            {end_time && moment(end_time).format("HH:mm")}
          </Paragraph2>
        </Block>
        <Paragraph2>
          {price} {renderRateUnit(rateUnit)}
        </Paragraph2>
      </Block>
    </Block>
  )
}

export default JobList
